export default function useDelegateToRouter() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const getRouteBaseName = useRouteBaseName();
  const localeRoute = useLocaleRoute();
  const route = useRoute();
  const router = useRouter();
  const runtimeConfig = useRuntimeConfig();

  /********************
   * FUNCTIONS         *
   ********************/
  function delegateLinksToRouter($event: MouseEvent) {
    let { target } = $event;
    while (target && (target as HTMLElement).tagName !== 'A') {
      target = (target as HTMLAnchorElement).parentNode;
    }

    const ciBaseUrl = 'https://www.camping.info';
    const baseUrl = runtimeConfig.public.baseUrl || ciBaseUrl;

    const ciBaseUrlGo = `${ciBaseUrl}/go/`;
    const baseUrlGo = `${baseUrl.replace(/\/$/, '')}/go/`;

    let href = (target as HTMLAnchorElement)?.getAttribute?.('href');

    // Detect Anchor Link
    if (href?.startsWith('#')) {
      const element = document.getElementById(href.replace(/#/g, ''));

      if (element) {
        $event.preventDefault();
        element.scrollIntoView({ behavior: 'smooth' });
        return navigateTo({
          name: route.name || undefined,
          params: route.params,
          query: route.query,
          hash: href,
        });
      }

      return;
    }

    if (href?.startsWith(baseUrl) && !href?.startsWith(baseUrlGo)) {
      href = href.substring(baseUrl.length);
    } else if (href?.startsWith(ciBaseUrl) && !href?.startsWith(ciBaseUrlGo)) {
      href = href.substring(ciBaseUrl.length);
    }

    if (target) {
      const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = $event;

      if (metaKey || altKey || ctrlKey || shiftKey || defaultPrevented) {
        return;
      }

      if (button !== undefined && button !== 0) {
        return;
      }

      if (target && (target as HTMLAnchorElement).getAttribute) {
        const linkTarget = (target as HTMLAnchorElement).getAttribute('target') || '';
        if (/\b_blank\b/i.test(linkTarget)) {
          return;
        }
      }

      if (!isInternalUrl(href, baseUrl) || !$event.preventDefault) {
        return;
      }

      const url = new URL((target as HTMLAnchorElement).href);
      const to = url.pathname + url.search;
      const hash = {};

      if (isAnchorUrl(href)) {
        hash.hash = '#' + href.split('#').pop();
      }

      const resolvedRoute = router.resolve(to);
      if (resolvedRoute.matched.length) {
        if (resolvedRoute.fullPath === route.fullPath) {
          return;
        }

        $event.preventDefault();
        const routeBaseName = getRouteBaseName(resolvedRoute.matched[0]);

        if (resolvedRoute && routeBaseName) {
          return navigateTo(localeRoute({ ...resolvedRoute, name: routeBaseName, ...hash }));
        }
      }
    }
  }

  return {
    delegateLinksToRouter,
  };
}
